import { DataTypes, Op } from '../utils/DataTypes';
import AppModel from '../core/AppModel';
import Helper from '../core/Helper';
import Schema from '../core/Schema';

export default class Cliente extends AppModel {
  constructor(sequelize, origin,crud) {
    super(sequelize, origin,crud);
    this.atributos = {
			id: {
				allowNull: false,
				autoIncrement: true,
				primaryKey: true,
				type: DataTypes['BIGINT'],
				//defaultValue: NULL
			},
			asentamiento_id: {
				allowNull: false,
				type: DataTypes['INTEGER'],
				references: {
					model: 'Asentamiento',
					key: 'id'
				}
			},
			tipo_cliente_id: {
				allowNull: false,
				type: DataTypes['INTEGER'],
				references: {
					model: 'TipoCliente',
					key: 'id'
				}
			},
			razon_social: {
				allowNull: true,
				type: DataTypes['STRING'](200),
			},
			encargado: {
				allowNull: true,
				type: DataTypes['STRING'](200),
			},
			es_empresa: {
				allowNull: false,
				type: DataTypes['BOOLEAN'],
				defaultValue: true,
			},
			telefono: {
				allowNull: true,
				type: DataTypes['STRING'](15),
				unique: true,
			},
			movil: {
				allowNull: true,
				type: DataTypes['STRING'](15),
				unique: true,
			},
			calles: {
				allowNull: false,
				type: DataTypes['STRING'](255),
			},
			andador: {
				allowNull: true,
				type: DataTypes['STRING'](255),
			},
			manzana: {
				allowNull: true,
				type: DataTypes['STRING'](255),
			},
			seccion: {
				allowNull: true,
				type: DataTypes['STRING'](255),
			},
			numero_exterior: {
				allowNull: true,
				type: DataTypes['STRING'](7),
			},
			numero_interior: {
				allowNull: true,
				type: DataTypes['STRING'](7),
			},
			notas: {
				allowNull: true,
				type: DataTypes['TEXT'],
			},
			created_at: {
				allowNull: true,
				type: DataTypes['DATE'],
				defaultValue: DataTypes['NOW']
			},
			updated_at: {
				allowNull: true,
				type: DataTypes['DATE'],
				defaultValue: DataTypes['NOW']
			}
		};
		this.fieldsValids = Object.keys(this.atributos);
		this.options = {
      tableName: 'clientes',
			modelName: 'Cliente',
			timestamps: false,
			freezeTableName: true,
			underscored: true,
      // Hooks: https://sequelize.org/master/manual/hooks.html
    };
    if(this.crud) {
			this.model = sequelize.define(this.options.modelName, this.atributos, this.options);
		}
  }// constructor.
  
  includeAssociations() {
		return [
			{
				model: this.sequelize.models.Asentamiento, 
				as:'asentamiento', 
				attributes: ['sucursal_id','asentamiento','tipo_asentamiento','cp','estado','municipio', 'id'],
				include: [{
					model: this.sequelize.models.Sucursal, 
					as:'a_sucursal'
				}]
			},
			{ model: this.sequelize.models.TipoCliente,  as:'tipo' },
			{ model: this.sequelize.models.Precio,  as:'c_precios' },
			//{ model: this.sequelize.models.Pedido,  as:'pedidos' },
		];
	}
	
  /**
   * @override
  **/
  async _findLike(data) {
		let where = { [data.campo]: {[Op.like]:'%' + data.valor + '%'} };
		if(data.campo === 'nors') {
			where = { [Op.or]: [
				{ razon_social: {[Op.like]:'%' + data.valor + '%'} },
				{ encargado: {[Op.like]:'%' + data.valor + '%'} },
			] }; 
		}
		if(data.campo === 'telefono') {
			where = { [Op.or]: [
				{ telefono: {[Op.like]:'%' + data.valor + '%'} },
				{ movil: {[Op.like]:'%' + data.valor + '%'} },
			] }; 
		}
		return await this.model.findAll({
			where,
			attributes: this.fieldsValids,
			include: this.includeAssociations(),
			order: [[this.primaryKey, 'ASC']],
			limit:20,
		});
	}
  
  /**
   * @override
   * */
  async validaciones(job) {
		let data = job.data;
		if(job.action == '_save') {
			if(!data.telefono && !data.movil) {
				return {error: true, message: 'Debes agregar al menos telefono o movil'};
			}
			if(!data.tipo_cliente_id) {
				return {error: true, message: 'Debes de seleccionar el Tipo de Cliente'};
			}
			if(!data.asentamiento_id) {
				return {error: true, message: 'Se debe asignar un asentamiento'};
			}
			if(this.crud) {
				let result = await Schema.models.Asentamiento._find(data.asentamiento_id);
				if(!result || !result.sucursal_id) {
					return {error: true, message: 'Se debe asignar un asentamiento con servicio de reparto'};
				}
				let where = {};
				if(!!data.telefono && !!data.movil) {
					where =  { 
						[Op.or]: [
							{ telefono: data.telefono} ,
							{ movil: data.movil || null},
						]
					};
				} else if(!!data.telefono) {
					where = {telefono: data.telefono};
				} else if(!!data.movil) {
					where = {movil: data.movil};
				}
				if(!!data.id) {
					where['id'] = { [Op.ne]: data.id };
				}
				result = await this.model.findAll({
					where,
					attributes: this.fieldsValids,
					order: [[this.primaryKey, 'ASC']],
				});
				console.log("encontrados en las validaciones",result);
				if(!!result && result.length > 0)  {
					return {error: true, message: 'No se pueden agregar dos clientes con el mismo telefono y/o movil'};
				}
			}
		}
		return {error: false, message: ''};
	}
  
}
