<template>
	<v-card style="height: 5vh; padding:7px 3px 0 3px; margin:0; font-size:10px; background-color:#E6E6FA; border-radius:50px;" 
	elevation="0" outlined > 
		<v-row class="no-mp">
			<v-col class="no-mp" cols="2" v-for="(f,i) in fechas" :key="i">
				<v-tooltip top>
					<template v-slot:activator="{ on, attrs }">
						<v-chip v-bind="attrs" v-on="on" dark x-small :class="f.estado"><i :class="'fa fa-'+f.icon "></i></v-chip>
					</template>
					<span>{{f.texto}}: {{f.fecha|dateTime}}</span>
				</v-tooltip>
			</v-col>
		</v-row>
	</v-card>
</template>

<script>
import Helper from '../../core/Helper.js';
export default {
  components: {},
  data: () => ({ }),
	props: {
		pedido: {
			type: Object,
			default() { return null;}
		},
	},
	mounted() { },
	methods: { },
	computed: {
		fechas() {
			let fechas = [];
			if(!!this.pedido.created_at) {
				fechas.push({ 
					icon:'file', 
					texto:'Capturado', 
					estado:'apertura', 
					fecha: this.pedido.created_at
				});
			}
			if(!!this.pedido.fecha_in_produccion) {
				fechas.push({ 
					icon:'upload', 
					texto:'Entrada produccion', 
					estado:'produccion',
					fecha: this.pedido.fecha_in_produccion
				});
			}
			if(!!this.pedido.fecha_out_produccion) {
				fechas.push({ 
					icon:'download', 
					texto:'Salida Produccion', 
					estado:'produccion',
					fecha: this.pedido.fecha_out_produccion
				});
			}
			if(!!this.pedido.fecha_reparto) {
				fechas.push({ 
					icon:'motorcycle', 
					texto:'Enviado Reparto:', 
					estado:'reparto',
					fecha: this.pedido.fecha_reparto
				});
			}
			if(!!this.pedido.fecha_entregado) {
				fechas.push({ 
					icon:'check', 
					texto:'Entregado', 
					estado:'cobro',
					fecha: this.pedido.fecha_entregado
				});
			}
			if(!!this.pedido.fecha_finalizado) {
				fechas.push({ 
					icon:'exclamation-circle', 
					texto:'Finalizado', 
					estado:'finalizado',
					fecha: this.pedido.fecha_finalizado
				});
			}
			return fechas;
		}
	},
};
</script>
<style>
</style>
