<template>
	<v-card>
		<v-toolbar color="grey" dark flat> 
			<v-btn icon v-on:click="finalizar" > <i class="fa fa-times"></i></v-btn>
			<v-spacer></v-spacer>
			<v-card-title> Informacion del TipoCliente</v-card-title>
		</v-toolbar>
    <v-card-text>
			<v-form ref="form" lazy-validation>
			<v-text-field v-model="tipoCliente.nombre" label="Nombre de tipo cliente"></v-text-field>
			</v-form>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="red" v-on:click="finalizar()" >
        Cancelar
      </v-btn>
      <v-btn color="primary" v-on:click="guardarTipoCliente()" >
        Guardar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
	import Schema from '../../core/Schema';
  export default {
    components:{},
    props: {
			tipoCliente: {
				type: Object,
				default() {return {
					nombre:null,
				};},
			}
		},
    data: () => ({
			asentaAux: null,
			reglas: {},
		}),
		mounted() {},
    methods: {
			guardarTipoCliente() {
				Schema.models.TipoCliente.guardar(this.tipoCliente,'all',job => {
					this.$emit('gettipocliente',job.newData);
				});
			},
			finalizar() {
				this.$emit('gettipocliente',null);
			},
		},
		computed: {},
		watch: {},
	}
</script>
<style>
</style>
