<template>
	<v-card  otulined style="padding:5px">
		<div v-if="buscarCliente">
			<v-text-field v-model="valorBuscar" label="Nombre o Razon social" v-on:keyup.enter="buscar()"></v-text-field>
			<v-card elevation="0" outlined style="margin:3px 0 3px 0; padding:2px;"
			v-for="(c,index) in clientes" :key="index" v-on:click="selec(c)">
				<div class="small-text">{{nombre(c)}}</div>
				<div class="xsmall-text">{{direccion(c)}}</div>
			</v-card>
		</div>
		<div v-else v-on:click="buscarCliente=true" class="boton">
			<div v-if="clienteSelec">{{nombre(clienteSelec)}}</div>
			<div v-else>Buscar Cliente</div>
		</div>
	</v-card>
</template>

<script>
	import Schema from '../../core/Schema';
  export default {
    components:{},
    props: {},
    data: () => ({
			buscarCliente:false,
			clientes:[],
			valorBuscar:null,
			clienteSelec:null,
		}),
		mounted() {},
    methods: {
			buscar() {
				if(!this.valorBuscar || this.valorBuscar.length <= 0) {
					return;
				}
				this.$store.commit('setsplash',{texto:'Cargando', activar: true});
				Schema.models.Cliente.buscarSimilar('nors', this.valorBuscar, job => {
					this.$store.commit('setsplash',{texto:'', activar: false});
					this.clientes = job.newData;
				});
			},
			nombre(c) {
				return c.es_empresa?c.razon_social:c.encargado;
			},
			direccion(c) {
				return c.calles + ' ' + c.numero_exterior;
			},
			selec(c) {
				this.clienteSelec = c;
				this.$emit('getcliente',c);
				this.buscarCliente = false;
			}
		},
		computed: {},
		watch: {},
	}
</script>
<style>
.boton {
	text-align:center;
	cursor:pointer;
}
</style>
