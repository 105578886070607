import { DataTypes } from '../utils/DataTypes';
import AppModel from './AppModel';
import Helper from './Helper';
import Schema from './Schema';

export default class Usuario extends AppModel {
  constructor(sequelize, origin,crud) {
    super(sequelize, origin,crud);
    this.atributos = {
			token: {
				allowNull: false,
				primaryKey: true,
				type: DataTypes['STRING']
			},
			user_id: {
				allowNull: false,
				type: DataTypes['STRING']
			},
			socket_id: {
				allowNull: true,
				type: DataTypes['STRING']
			},
			conectado: {
				allowNull: true,
				type: DataTypes['BOOLEAN'],
				defaultValue:false,
			},
			metainfo: {
				allowNull: true,
				type: DataTypes['STRING']
			},
			ultima_vez_conectado: {
				allowNull: false,
				type: DataTypes['DATE']
			},
			updated_at: {
				allowNull: false,
				type: DataTypes['DATE'],
				defaultValue: DataTypes['NOW']
			},
			created_at: {
				allowNull: false,
				type: DataTypes['DATE'],
				defaultValue: DataTypes['NOW']
			},
		};
		this.primaryKey = 'token';
		this.fieldsValids = Object.keys(this.atributos);
		this.options = {
      tableName: 'sesiones',
			modelName: 'Sesion',
			timestamps: false,
			freezeTableName: true,
			underscored: true,
      // Hooks: https://sequelize.org/master/manual/hooks.html
    };
    if(this.crud) {
			this.model = sequelize.define(this.options.modelName, this.atributos, this.options);
		}
		this.socketSession = null;
  }// constructor.
  
  includeAssociations() {
		return [
			{model: this.sequelize.models.User, as: 's_user'},
		];
	}
	
	iniciarSesion(campo,valor,password,handler,metainfo) {
		let job = this.createJob({campo,valor,password,metainfo},'_login','all');
		this.setHandler(handler,job);
		this.processJob(job).then((theJob) => {
			console.log("asdfasd",theJob);
			if(this.crud && job.action == '_login' && theJob.newData.error == 0) {
				this.store.commit('setuser',theJob.newData.user);
				localStorage.setItem('token',theJob.newData.token);
				localStorage.setItem('user_id',theJob.newData.user.id);
			}
			this.sendJobToServer(theJob);
		});
	}
	
	cerrarSesion() {
		let token = localStorage.getItem('token');
		this.store.commit('setuser',null);
		localStorage.removeItem('token');
		localStorage.removeItem('user_id');
		let job = this.createJob({token},'_closeSession','all');
		this.processJob(job).then((theJob) => {
			this.sendJobToServer(theJob);
		});
	}
	
	tieneSesion(metainfo,handler) {
		let token = localStorage.getItem('token');
		let user_id = localStorage.getItem('user_id');
		let job = this.createJob({metainfo, token, user_id, socket_id:this.socketCli? this.socketCli.id:null},'_hasSession','all');
		this.setHandler(handler,job);
		this.processJob(job).then((theJob) => {
			this.sendJobToServer(theJob);
		});
	}
	
	desconectar(socketId) {
		console.log("se desconecto",socketId);
		let job = this.createJob(socketId,'_dissconnect','all');
		this.processJob(job).then((theJob) => {
			this.broadcast(theJob);
		});
	}
	
	async _dissconnect(socketId) {
		let sesion = [];
		sesion = await this._findBy({campo:'socket_id', valor:socketId});
		if(sesion.length > 0 && sesion[0]) {
			sesion[0].conectado = false;
			sesion[0].socket_id = null;
			sesion[0].ultima_vez_conectado = Helper.fechaHora();
			await sesion[0].save();
		}
		return null;
	}
	
	async _login(data) {
		let user = await this.sequelize.models.User.findAll( {where:{
			[data.campo]: data.valor,
		}});
		user = user[0]?user[0]:null;
		console.log("login usuario encontrado" + this.origin,user);
		if(user) {
			if(Helper.hash(data.password) === user.password) {
				await this.model.destroy({where: {user_id: user.id }});
				return {error:0, user, token: await this._tokenSession(user,data.metainfo), ...data};
			} else {
				return {error:1,message:'La contraseña es incorrecta', ...data};
			}
		} 
		return {error:1,message:'El usuario no existe', ...data};
	}
	
	async _tokenSession(user,metainfo) {
		metainfo = JSON.stringify(metainfo);
		let sesion = await this.model.create({ 
			user_id:user.id , 
			metainfo, 
			conectado:true, 
			socket_id: this.socketCli? this.socketCli.id:null,
			updated_at:Helper.fechaHora(),
			ultima_vez_conectado: Helper.fechaHora(),
			token:Helper.hash(Helper.fechaHora() + '' + user.password),
		});
		console.log("sesion creada en " + this.origin,sesion.token);
		await sesion.save();
		return sesion.token;
	}
	
	async _hasSession(data) {
		let sesion = await this._find(data.token);
		if(!!sesion) {
			sesion.conectado = true;
			sesion.socket_id = data.socket_id;
			sesion.updated_at = Helper.fechaHora();
			sesion.metainfo = JSON.stringify(data.metainfo) ;
			await sesion.save();
			return {tiene_sesion:true, sesion};
		}
		return {tiene_sesion:false, sesion};
	}
	
	async _closeSession(data) {
		let sesion = await this._find(data.token);
		if(sesion) {
			sesion.destroy();
		}
		return true;
	}
	
	/**
	 * @override
	**/
	sendJobToServer(job) {
		if(this.socketCli && this.socketCli.connected) {
			this.socketCli.emit('auth_channel_server',job);
		}
	}
	
	/**
	 * @override
	**/
	broadcast(job) {
		if(job.broadcast == 'all') {
			this.socketIO.emit('auth_channel_client',job);
		}
		if(job.broadcast == 'own') {
			this.socketCli.emit('auth_channel_client',job);
		}
		if(job.broadcast == 'destiny') {
			this.socketIO.to(job.destiny).emit('auth_channel_client',job);
		}
			
	}
	
	/**
	 * @override
	**/
	handlerResult(job) {
		if(this.origin === 'server') {
			return;
		}
		if(!!this.handlers[job.action +'-'+job.model] && typeof this.handlers[job.action +'-'+job.model] === 'function' 
		&&  this.isMyJob(job)) { 
			console.log("handler",job);
			if(job.action == '_login' && job.newData.error == 0) {
				this.store.commit('setuser',job.newData.user);
				localStorage.setItem('token',job.newData.token);
				localStorage.setItem('user_id',job.newData.user.id);
			}
			this.handlers[job.action +'-'+job.model](job);
			this.handlers[job.action +'-'+job.model] = null;
		}
	}
	
	/**
	 * @override
	**/
	async enviarDatosAlCliente(socket,fecha) {
		let data = await this._dataSince({fecha});
		console.log("enviar al cliente desde " + fecha + ' ' + this.options.modelName, data.length);
		socket.emit('auth_channel_client',{
			data,
			newData: data,
			action:'_saveAll',
			socket_id:socket.id,
			broadcast:'own',
			model:this.options.modelName,
		});
	}
	
	sesionesActivas(handler) {
		this.attachSelectJob({},'_sesionesActivas',handler);
	}
	
	async _sesionesActivas() {
		return await this.model.findAll({
			where: { conectado: 1 }
		});
	}
}
