import socketIO from 'socket.io';
const io = require("socket.io-client");

export default {
	socket: null, 
	ioSockets: null,
	
	initServer(httpServer,url) {
		this.ioSockets = socketIO(httpServer, {
			cors: {
				origin: url,
				methods: ["GET", "POST"],
				credentials: true,
			}
		});
    return true; 
  },
  
  getSocket() {
		return this.socket;
	},
  
  initClient(url) {
		if(this.socket && this.socket.connected) {
			return;
		} 
		this.socket = io(url, {
			withCredentials: true,
		});
  },
}
