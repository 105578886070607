import { DataTypes, Op } from '../utils/DataTypes';
import AppModel from '../core/AppModel';
import Helper from '../core/Helper';
import municipios from '../utils/municipios';

export default class Asentamiento extends AppModel {
  constructor(sequelize, socket, origin,crud) {
    super(sequelize, socket, origin,crud);
    this.atributos = {
			id: {
				allowNull: false,
				autoIncrement: true,
				primaryKey: true,
				type: DataTypes['BIGINT'],
				//defaultValue: NULL
			},
			sucursal_id: {
				allowNull: false,
				type: DataTypes['INTEGER'],
				references: {
					model: 'Sucursal',
					key: 'id'
				}
			},
			cp: {
				allowNull: false,
				type: DataTypes['INTEGER'],
			},
			asentamiento: {
				allowNull: false,
				type: DataTypes['TEXT'],
			},
			tipo_asentamiento: {
				allowNull: false,
				type: DataTypes['STRING'](30),
			},
			municipio: {
				allowNull: true,
				type: DataTypes['TEXT'],
			},
			estado: {
				allowNull: true,
				type: DataTypes['STRING'](50),
			},
			ciudad: {
				allowNull: true,
				type: DataTypes['TEXT'],
			},
			updated_at: {
				allowNull: true,
				type: DataTypes['DATE'],
				defaultValue: DataTypes['NOW'],
			}
		};
		this.fieldsValids = Object.keys(this.atributos);
		this.options = {
      tableName: 'asentamientos',
			modelName: 'Asentamiento',
			timestamps: false,
			freezeTableName: true,
			underscored: true,
      // Hooks: https://sequelize.org/master/manual/hooks.html
    };
    if(this.crud) {
			this.model = sequelize.define(this.options.modelName, this.atributos, this.options);
		}
  }// constructor.
  
  includeAssociations() {
		return [
			{model:this.sequelize.models.Sucursal, as:'a_sucursal'},
			//{model:this.sequelize.models.Cliente, as:'a_clientes'},
		];
	}
	
	
	buscarPorNombre(nombre,handler) {
		this.setHandler(handler);
		let job = this.createJob({nombre},'_findByName','own');
		this.processJob(job).then((theJob) => {
			this.sendJobToServer(theJob);
		});
	}
  
  async _findByName(data) {
		return await this.model.findAll({
			where:{
				asentamiento: {[Op.like]:'%' + data.nombre + '%'},
				municipio: {[Op.in]: municipios}
			},
			attributes: this.fieldsValids,
			include: this.includeAssociations(),
			order: [['id', 'ASC']]
		});
	}
	
	/**
	 * @override
	 * **/
	 
	buscarSimilar(valor, handler) {
		this.attachSelectJob({valor, limit:30},'_findLike',handler);
	}
	
	/**
	 * @override
	 * **/
  
  async _findLike(data) {
		return await this.model.findAll({
			where:{
				asentamiento: {[Op.like]:'%' + data.valor + '%'},
				municipio: {[Op.in]: municipios}
			},
			attributes: this.fieldsValids,
			include: this.includeAssociations(),
			order: [[this.primaryKey, 'ASC']],
			limit:data.limit
		});
	}
	
	/**
	 * @override
	 * **/
  async _count() {
		return await this.model.count({
			where:{
				municipio: {[Op.in]: municipios}
			}
		});
	}
}
