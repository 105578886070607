<template>
	<div>
	<v-card height="100%" tile>
		<v-toolbar class="sub-cabecera" dark  dense> 
			<!--v-btn icon v-on:click="cerrar" > <i class="fa fa-times"></i></v-btn-->
			<v-spacer></v-spacer>
			<v-card-title>Productos</v-card-title>
		</v-toolbar>
		<v-card-text>
			<v-row style="margin:0 0 0 0; padding:5px">
				<v-col class="no-mp" cols="2">
					<v-btn color="success" v-on:click="nuevo">Crear Producto</v-btn>
				</v-col>
				<v-col class="no-mp" cols="5"></v-col>
				<v-col class="no-mp" cols="5">
					<v-card style="border-radius:25px 25px 25px 25px; background-color:#6BB1F5"  elevation="1"  dark>
						<v-row class="no-mp">
							<v-col style="margin:0; padding:2vh 0 0 0; font-size:4vh; text-align:center; color:#FFFFFF;" cols="2">
								<i class="fa fa-search"></i>
							</v-col>
							<v-col class="no-mp" cols="10">
								<v-text-field filled rounded dense label="Buscar" :hide-details="true" v-model="valorBuscar" 
								v-on:keyup.enter="buscar"></v-text-field>
							</v-col>
						</v-row>
					</v-card>
				</v-col>
			</v-row>
			<v-card style="background-color:transparent!important" elevation="0">
				<v-card elevation="0" style="padding:5px 17px 5px 10px;margin:0; background-color:#F8F8F8;" outlined>
					<v-row class="no-mp">
						<v-col cols="3" class="no-mp">Nombre</v-col>
						<v-col cols="2" class="no-mp">UM</v-col>
						<v-col cols="3" class="no-mp">Categoria</v-col>
						<v-col cols="2" class="no-mp">Clave</v-col>
						<v-col cols="2" class="no-mp">Acciones</v-col>
					</v-row>
				</v-card>
				<v-card elevation="0" style="padding:1px 5px 1px 5px;margin:5px 0 0 0;background-color:rgba(16,45,80,0.1); 
				height:55vh; overflow-x: hidden; overflow-y: scroll;" outlined>
					<v-card elevation="0" style="padding:5px;margin:6px 0 0 0;" 
					v-for="(d, index) in productos" :key="index">
						<v-row style="padding:0px;margin:0;" >
							<v-col cols="3" class="no-mp" style="font-size:14px;">{{d.nombre}}</v-col>
							<v-col cols="2" class="no-mp" style="font-size:14px;">{{d.unidad_medida}}</v-col>
							<v-col cols="3" class="no-mp" style="font-size:14px;">{{d.categoria}}</v-col>
							<v-col cols="2" class="no-mp" style="font-size:14px;">{{d.clave}}</v-col>
							<v-col cols="2" class="no-mp">
								<v-btn-toggle>
									<v-btn x-small :key="'pre'" depressed color="primary" v-on:click="precios(d)"><i class="fa fa-list"></i></v-btn>
									<v-btn x-small :key="'edi'" depressed dark color="warning" v-on:click="editar(d,index)"><i class="fa fa-edit"></i></v-btn>
									<v-btn x-small :key="'tra'" depressed dark color="red" v-on:click="borrar(d,index)"><i class="fa fa-trash"></i></v-btn>
								</v-btn-toggle>
							</v-col>
						</v-row>
					</v-card>
				</v-card>
			</v-card>
		</v-card-text>
		<div class="text-center" v-if="numPaginas > 0">
			<v-pagination v-model="paginaActual" :length="numPaginas" ></v-pagination>
		</div>
	</v-card>
		<!-- -->
		<v-dialog v-model="formModal" width="400" >
			<ProductoForm v-if="editarItem" :producto="editarItem" v-on:getproducto="getProducto" :key="'edi'"/>
			<ProductoForm v-else-if="formModal" v-on:getproducto="getProducto" :key="'new'"/>
		</v-dialog>
		<!-- -->
		<!-- -->
		<v-dialog v-model="formPrecios" width="800" >
			<PreciosForm v-if="editarItem" :precios="editarItem.p_precios" :producto="editarItem" v-on:getprecios="getPrecios" :key="'prec'"/>
		</v-dialog>
		<!-- -->
  </div>
</template>

<script>
	import Schema from '../core/Schema';
	import ProductoForm from '../reutilizables/productos/ProductoForm';
	import PreciosForm from '../reutilizables/precios/PreciosForm';
  export default {
    name: 'ClienteBuscar',
    components:{
			ProductoForm,
			PreciosForm,
		},
    props: { },
    data () { return {
			paginaActual:0,
			numPaginas:0,
			valorBuscar: null,
			editarItem: null,
			formModal: false,
			formPrecios: false,
			productos: [],
		};},
		mounted() {
			let self = this;
			this.paginar();
		},
    methods: {
			paginar() {
				this.$store.commit('setsplash',{texto:'Cargando', activar: true});
				Schema.models.Producto.count((count) => {
					console.log("count ", count);
					let itemsPorPagina = 30;
					this.numPaginas = Math.ceil(count.newData / itemsPorPagina);
					console.log("productos " + count.newData,this.numPaginas);
					Schema.models.Producto.listar(itemsPorPagina, this.paginaActual, (job) => {
						this.$store.commit('setsplash',{texto:'', activar: false});
						this.productos = job.newData;
					});
				});
				
			},
			buscar() {
				let self = this;
				if(!this.valorBuscar || this.valorBuscar.length <= 0) {
					this.paginar();
					return;
				}
				this.$store.commit('setsplash',{texto:'Cargando', activar: true});
				Schema.models.Producto.buscarSimilar('nombre', this.valorBuscar, (result) => {
					this.$store.commit('setsplash',{texto:'', activar: false});
					this.productos = result.newData;
				});
			},
			nuevo() {
				this.index = this.productos.length;
				this.editarItem = null;
				this.formModal = true;
			},
			editar(item,index) {
				this.index = index;
				this.editarItem = item;
				this.formModal = true;
			},
			borrar(item, index) {
			},
			precios(prod) {
				this.editarItem = prod;
				this.formPrecios = true;
			},
			getProducto(p) {
				if(p) {
					p['precios'] = [];
					this.productos.splice(this.index,1,p);
				}
				this.formModal = false;
			},
			getPrecios(p) {
				this.formPrecios = false;
			}
		},
		computed: {},
		watch: {
			paginaActual() {
				this.paginar();
			}
		}
  }
</script>
<style>
</style>
