<template>
	<v-container>
		<v-row>
			<v-col></v-col>
			<v-col>
				<v-card>
					<v-card-title class="text-center">Iniciar sesion</v-card-title>
					<v-divider></v-divider>
					<v-card-text>
						<v-row class="no-mp">
							<v-col style="margin:10px 0 0 0; padding:0;"><v-text-field label="Usuario" v-model="user" solo 
							v-on:keyup.enter="login"></v-text-field></v-col>
						</v-row>
						<v-row class="no-mp">
							<v-col style="margin:0; padding:0;"><v-text-field type="password" v-model="pass" label="Contraseña" solo 
							v-on:keyup.enter="login"></v-text-field></v-col>
						</v-row>
					</v-card-text>
					<v-divider></v-divider>
					<v-card-actions>
						<v-btn color="success" v-on:click="login" block>Ingresar</v-btn>
					</v-card-actions>
				</v-card>
			</v-col>
			<v-col></v-col>
		</v-row>
	</v-container>
</template>

<script>

import Schema from '../core/Schema';
import Helper from '../core/Helper.js';

export default {
  components: { },
	props: { },
  data: () => ({ 
		user:null,
		pass:null,
	}),
	mounted() {},
	methods: { 
		login() {
			this.$store.commit('setsplash',{texto:'Iniciando Sesion', activar: true});
			Schema.Auth.iniciarSesion('username',this.user,this.pass, job => {
				this.$store.commit('setsplash',{texto:'', activar: false});
				if(job.newData.error) {
					alert(job.newData.message);
				} else {
					this.$emit('onlogin',job.newData);
				}
			},{sucursal_id:0});
		},
	},
	computed: { },
};
</script>

<style>
	
</style>
