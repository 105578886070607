<template>
	<v-app style="background-color:#FFFFFF" v-if="listo">
		<v-app-bar app dark v-if="logeado" class="cabecera" elevation="0">
			<v-spacer></v-spacer>
			<v-btn color="warning" fab dark elevation="0" v-on:click="cerrarSesion">
				<i class="fa fa-user"></i>
			</v-btn>
    </v-app-bar>
    
    <v-navigation-drawer app  permanent v-if="logeado" color="#123356" dark width="200"> 
			<v-list-item class="no-mp"> 
         <v-list-item-content class="no-mp text-center">
					 <div >
						 <img :src="logo" style="width:60px;" />
					 </div>
         </v-list-item-content>
			</v-list-item>
			<v-divider></v-divider>
			<v-list>
				<v-card v-for="i in menuItems" :key="i.id+''+i.nombre" v-on:click="accion(i)" elevation="0" 
				style="margin:6px; padding:5px; background-color:#154272" >
					<v-row class="no-mp">
						<v-col style="font-size:13px; margin:0; padding:0;" cols="2"><i :class="'fa fa-'+i.icon"></i></v-col>
						<v-col style="font-size:12px; margin:0; padding:0;" cols="10">{{i.nombre}}</v-col>
					</v-row>
				</v-card>
			</v-list>
		</v-navigation-drawer>
    
		<v-main v-if="!logeado">
			<Login v-on:onlogin="onLogin"/>
		</v-main> 
    <v-main v-else>
			<v-window vertical v-model="window">
				<!-- vistas principales-->
				<v-window-item>  <DashBoard v-if="window == 0"/> </v-window-item>
				<v-window-item>  <Clientes v-if="window == 1"/> </v-window-item>
				<v-window-item>  <Usuarios v-if="window == 2"/> </v-window-item>
				<v-window-item>  <TipoDeClientes v-if="window == 3"/> </v-window-item>
				<v-window-item>  <Productos v-if="window == 4"/> </v-window-item>
				<v-window-item>  <AsignarColonias v-if="window == 5"/> </v-window-item>
				<v-window-item>  <Sucursales v-if="window == 6"/> </v-window-item>
				<!-- vistas extras-->
			</v-window>
		</v-main> 
		
		<!-- crear cliente-->
		<v-dialog v-model="agregarCliente" width="800" >
			<ClienteForm v-if="agregarCliente" v-on:getcliente="cerrarFormCliente"/>
		</v-dialog>
		<!-- ./crear cliente-->
		
		<!-- splash-->
		 <v-dialog v-model="splash"  persistent width="300" >
      <v-card color="primary" dark >
        <v-card-text>
          {{textSplash}}
          <v-progress-linear indeterminate color="white" class="mb-0" ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
		<!-- ./splash-->
  </v-app>
</template>

<script>
import Aplicacion from './utils/App'
import Login from './vistas/Login'
import DashBoard from './vistas/DashBoard'
import Clientes from './vistas/Clientes'
import Productos from './vistas/Productos'
import Usuarios from './vistas/Usuarios'
import TipoDeClientes from './vistas/TipoDeClientes'
import AsignarColonias from './vistas/AsignarColonias'
import Sucursales from './vistas/Sucursales'
import ClienteForm from './reutilizables/clientes/ClienteForm'
import Schema from './core/Schema';

export default {
  name: 'app',
  components:{
		Login,
		DashBoard,
		Clientes,
		Productos,
		Usuarios,
		AsignarColonias,
		ClienteForm,
		TipoDeClientes,
		Sucursales,
	},
  data () { return {
		listo:false,
		logeado:false,
		window:0,
		itemData: null,
		agregarCliente: false,
		menuItems:[
			{tipo:'vista', id:0, icon:'home',nombre:"DashBoard"}, 
			{tipo:'vista', id:2, icon:'user',nombre:"Usuarios"}, 
			{tipo:'vista', id:1, icon:'users',nombre:"Clientes"}, 
			{tipo:'over', id:0, icon:'user',nombre:"Crear Cliente"}, 
			{tipo:'vista', id:3, icon:'list',nombre:"Tipo Clientes"},
			{tipo:'vista', id:4, icon:'drumstick-bite',nombre:"Productos"}, 
			{tipo:'vista', id:5, icon:'map-marker',nombre:"Asentamientos"}, 
			{tipo:'vista', id:6, icon:'industry',nombre:"Sucursales"}, 
			 
			//{tipo:'vista', id:5, icon:'map-marker',nombre:"Asignar Colonias"},
		], 
	}},
	mounted() {
		Aplicacion.start().then(() => {
			this.listo = true;
			Schema.Auth.tieneSesion({sucursal_id:0},job => {
				if(!!job.newData && job.newData.tiene_sesion) {
					this.$store.commit('setuser',job.newData.user);
					this.logeado = true;
				}
			});
		});
	},
	methods: {
		accion(item) {
			this.itemData = item.data;
			if(item.tipo === 'vista') {
				this.window = item.id;
				console.log("ventana",this.window );
			}
			if(item.tipo === 'over') {
				switch(item.id) {
					case 0: this.agregarCliente = true; break;
				};
			}
		},
		cerrarFormCliente() {
			this.agregarCliente = false;
		},
		cerrarSesion() {
			Schema.Auth.cerrarSesion();
			this.logeado = false;
		},
		onLogin(data) {
			console.log('agregando susuario', data.user);
			this.$store.commit('setuser',data.user);
			this.logeado = true;
		}
	},
	computed: {
		logo() {
			return require('./imagenes/icono.png');
		},
		splash() {
			return this.$store.state.splash;
		},
		textSplash() {
			return this.$store.state.splashText;
		},
	},
	watch: {},
}
</script>

<style>
</style>
