<template>
	<v-card height="100%" tile>
		<v-toolbar class="sub-cabecera" dark  dense> 
			<!--v-btn icon v-on:click="cerrar" > <i class="fa fa-times"></i></v-btn-->
			<v-spacer></v-spacer>
			<v-card-title>Dashboard</v-card-title>
		</v-toolbar>
		<v-card style="padding:20px;">
			<v-row class="no-mp">
				<v-col class="no-mp" cols="4">
					<v-select label="Sucursal" :items="sucursales" :item-text="'nombre'" return-object v-model="suc"></v-select>
				</v-col>
				<!-- 
				<v-col class="no-mp" cols="4">
					<v-menu v-model="mostrarFecha" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
						offset-y min-width="auto" >
						<template v-slot:activator="{ on, attrs }">
							<v-text-field v-model="fechaSelect" label="Fecha" prepend-icon="mdi-calendar" readonly
							v-bind="attrs" v-on="on" ></v-text-field>
						</template>
						<v-date-picker v-model="fechaSelect" @input="mostrarFecha = false" ></v-date-picker>
					</v-menu>
				</v-col>
				<!-- -->
				<v-col class="no-mp" cols="4"></v-col>
			</v-row>
		</v-card>
		<v-card elevation="0" style="padding:5px;margin:5px; background-color:#F5F6FF;"> 
			<v-card style="height: 5vh; padding: 5px 0 0 10px; margin:5px; font-size:13px;" outlined>
				<v-row style="height: 5vh;" justify="space-around" align-items="align-stretch">
					<v-col cols="1">ID</v-col>
					<v-col cols="3">Cliente</v-col>
					<v-col cols="1">Total </v-col>
					<v-col cols="1">Atendio </v-col>
					<v-col cols="1">Repartidor</v-col>
					<v-col cols="3">Seguimiento del Pedido</v-col>
					<v-col cols="2">Acciones</v-col>
				</v-row>
			</v-card>
			<v-card style="height: 7vh; padding: 5px 0 0 10px; margin:5px; font-size:13px;" outlined
			v-for="p,index in pedidos" :key="index" :class="pedidoEstado(p)">
				<v-row style="height: 7vh;" justify="space-around" align-items="align-stretch">
					<v-col cols="1"><v-chip dark x-small color="red">{{p.id}}</v-chip></v-col>
					<v-col cols="3">{{nombre(p.cliente)}}</v-col>
					<v-col cols="1"><v-chip dark x-small color="success">$ {{p.total | moneda}}</v-chip></v-col>
					<v-col cols="1"><v-chip dark x-small>{{p.cajero.nombre}}</v-chip></v-col>
					<v-col cols="1"><v-chip dark x-small v-if="!!p.repartidor">{{p.repartidor.nombre}}</v-chip></v-col>
					<v-col cols="3"><PedidoEstatus :pedido="p" /></v-col>
					<v-col cols="2">
						<v-btn-toggle > 
							<v-btn color="indigo" small :key="'impr'" v-on:click="ver(p, index)">
								<i class="fa fa-eye"></i>
							</v-btn>
							<v-btn color="red" small :key="'can'" v-on:click="cancelar(p, index)">
								<i class="fa fa-ban"></i>
							</v-btn -->
						</v-btn-toggle>
					</v-col>
				</v-row>
			</v-card>
		</v-card>
		<!-- -->
		<v-dialog v-model="infoModal" width="1000" >
			<v-card v-if="pedidoSelect" :class="pedidoSelect.estado">
				<v-card-title>Pedido folio: <v-chip dark color="red"> {{' '+pedidoSelect.id}}</v-chip></v-card-title>
				<InformacionPedido :pedido="pedidoSelect" :tipo="'info'" :puede_abonar="false"/>
				<InformacionPedido :pedido="pedidoSelect" :tipo="'historial'" :puede_abonar="false"/>
				<v-divider></v-divider>
				<v-card-actions>
					<v-btn v-on:click="infoModal = false">Aceptar</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<!-- -->
		<!-- -->
		<v-dialog v-model="cancelacionModal" width="400" >
			<CancelacionForm v-if="cancelacionModal" :pedido="pedidoSelect" v-on:getcancelacion="getCancelacion"/>
		</v-dialog>
		<!-- -->
	</v-card>
</template>

<script>
import MasVendidos from '../reutilizables/productos/MasVendidos';
import PedidoEstatus from '../reutilizables/pedidos/PedidoEstatus';
import InformacionPedido from '../reutilizables/pedidos/InformacionPedido';
import CancelacionForm from '../reutilizables/cancelaciones/CancelacionForm';
import Schema from '../core/Schema';
import Helper from '../core/Helper';
export default {
  components: {
		MasVendidos,
		PedidoEstatus,
		InformacionPedido,
		CancelacionForm,
	},
  data () { return { 
		mostrarFecha:false,
		fechaSelect:null,
		pedidoSelect:null,
		infoModal:false,
		cancelacionModal: false,
		pedidoSelectIndex:null,
		pedidos:[],
		sucursales:[],
		suc:null,
	}},
	mounted() {
		this.$store.commit('setsplash',{texto:'Cargando...', activar: true});
		Schema.models.Sucursal.listar(null,null,job => {
			this.sucursales = job.newData;
			this.$store.commit('setsplash',{texto:'', activar: false});
		});
	},
	methods: {
		buscarPedidos() {
			this.$store.commit('setsplash',{texto:'Cargando...', activar: true});
			Schema.models.Pedido.pedidosDiaSucursal(this.suc.id, Helper.fecha(),job => {
				this.pedidos = job.newData;
				this.$store.commit('setsplash',{texto:'', activar: false});
			});
		},
		nombre(c) {
			return c.razon_social? c.razon_social: c.encargado;
		},
		ver(c, index) {
			this.pedidoSelect = c;
			this.pedidoSelectIndex = index;
			this.infoModal = true;
		},
		cancelar(c, index) {
			this.pedidoSelect = c;
			this.pedidoSelectIndex = index;
			this.cancelacionModal = true;
		},
		getCancelacion(c,p) {
			if(!!c) {
				this.pedidos.splice(this.pedidoSelectIndex,1,p);
			}
			this.cancelacionModal = false;
		},
		pedidoEstado(p) {
			return p.cancelado? 'cancelado':p.estado;
		},
	},
	computed: { },
	watch: {
		suc() {
			this.buscarPedidos();
		},
	},
}
</script>

<style>
</style>
