<template>
	<div>	
		<v-text-field v-model="valorBuscarAsenta" label="Colona, Fraccionamiento, Rancho, etc..." ></v-text-field>
		<v-container>
			<v-row v-if="asentamientos.length > 0"  class="lighten-5">
				<v-col class="d-flex" cols="3"> <b>Tipo </b></v-col>
				<v-col class="d-flex" cols="6"> <b>Asentamiento </b></v-col>
				<v-col class="d-flex" cols="3"> <b>Sucursal </b></v-col>
			</v-row>
			<v-row  v-for="(a, index) in asentamientos" :key="index" class="lighten-5 clicker" v-on:click="selectAsentamiento(a)">
				<v-col class="d-flex" cols="3"> {{a.tipo_asentamiento}} </v-col>
				<v-col class="d-flex" cols="6"> {{a.asentamiento}}, {{a.municipio}}  </v-col>
				<v-col class="d-flex" cols="3"> {{laSucursal(a)}} </v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
	import Schema from '../../core/Schema';
	import Helper from '../../core/Helper.js'; 
  export default {
    //mixins: [navegable],
    components:{},
    props: {},
    data () { return {
			valorBuscarAsenta:null,
			tab: null,
			asentamiento: null,
			asentamientos: [],
			sucursales: [],
		};},
		mounted() {},
    methods: {
			laSucursal(a) {
				return a.sucursal_id?a.a_sucursal.nombre:'Sin reparto';
			},
			selectAsentamiento(a) {
				if(!a.sucursal_id) {
					alert("No hay servicio de reparto en esa zona");
					return;
				}
				this.asentamiento = a;
				this.asentamientos = [];
				this.$emit('get-asentamiento',this.asentamiento);
			},
			buscarAsentamiento() {
				Schema.models.Asentamiento.buscarSimilar(this.valorBuscarAsenta, job => {
					console.log(job);
					if(job.newData.length > 0) {
						this.asentamientos = job.newData;
					} else {
						this.asentamiento = null;
					}
				});
			}
		},
		watch: {
			valorBuscarAsenta: function (value) {
				if(value && value.length > 0) {
					this.buscarAsentamiento();
				} else {
					this.asentamientos = [];
				}
			}
		},
		computed: {}
  }
</script>
<style>
	.clicker:hover {
		cursor: pointer;
		background-color:rgba(0,0,0,0.2);
	}
</style>
