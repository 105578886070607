<template>
	<div>
	<v-card height="100%" >
		<v-toolbar color="indigo" dark tile> 
			<!--v-btn icon v-on:click="cerrar" > <i class="fa fa-times"></i></v-btn-->
			<v-spacer></v-spacer>
			<v-card-title>Buscar Cliente</v-card-title>
		</v-toolbar>
		<v-card-text>
			<v-container fluid style="height: 13vh;">
				<v-row align="center"> 
					<v-col class="d-flex" cols="2">
						<v-select :items="itemsBuscarPor" :item-text="'name'"  v-model="buscarPor" :item-value="'value'" solo></v-select>
					</v-col>
					<v-col class="d-flex" cols="7">
						<v-text-field v-model="valorBuscar" :label="textoBuscarPor" id="valor_buscar" solo
						v-on:keyup.up="cambiarBuscarPor(1)" v-on:keyup.down="cambiarBuscarPor(-1)" 
						v-on:keyup.enter="buscar()"></v-text-field>
					</v-col>
				</v-row>
			</v-container>
			<v-card style=" height:450px; overflow-x: hidden; overflow-y: scroll; background-color:#F8F8F8;" elevation="0">
				<v-card style="height: 7vh; padding: 20px 20px 0 20px; margin: 10px 5px 0 5px;" outlined>
					<v-row style="height: 7vh;">
							<v-col cols="10" style="padding:0 0 0 0;"> 
								<v-row justify="space-around" align-items="align-stretch">
									<v-col cols="3"> Cliente</v-col>
									<v-col cols="2"> Telefono </v-col>
									<v-col cols="4"> Direccion </v-col>
									<v-col cols="2"> Sucursal </v-col>
								</v-row>
							</v-col>
							<v-col cols="2" style="padding:0 0 0 0;"> Acciones</v-col>
					</v-row>
				</v-card>
				<v-card style="height: 9vh; padding: 23px 20px 0 20px; margin: 10px 5px 0 5px;" outlined class="clicker"
				v-for="(c, index) in clientes" :key="index">
					<v-row style="height: 9vh;">
							<v-col cols="10" style="padding:0 0 0 0;"> 
								<v-row justify="space-around" align-items="align-stretch" v-on:click="selectCliente(c)">
									<v-col cols="3"> {{nombre(c)}}</v-col>
									<v-col cols="2"> {{telefonos(c)}}</v-col>
									<v-col cols="4"> {{direccionCompleta(c)}} </v-col>
									<v-col cols="2"> 
										<v-chip :class="colorSucursal(c )" v-if="c.asentamiento">
											{{c.asentamiento.a_sucursal.nombre | capitalize}} 
										</v-chip> 
									</v-col>
								</v-row>
							</v-col>
							<v-col cols="2" style="padding:0 0 0 0;">
								<v-btn-toggle > 
									<!--v-btn small color="primary" v-on:click="productos(c)"><i class="fa fa-list"></i></v-btn--> 
									<v-btn small color="warning" v-on:click="editarCli(c)" :key="'edt'"><i class="fa fa-edit"></i></v-btn> 
									<!--v-btn small color="indigo" v-on:click="historial(c)"><i class="fa fa-clipboard"></i></v-btn--> 
								</v-btn-toggle>
							</v-col>
					</v-row>
				</v-card>
			</v-card>
		</v-card-text>
	</v-card>
		<!--  cliente-->
		<v-dialog v-model="editarCliente" width="800" >
			<ClienteForm v-if="editarCliente" :cliente="clienteEdit" v-on:getcliente="obtenerClienteDeFormulario"/>
		</v-dialog>
		<!-- ./ cliente-->
		<!--  historial-->
		<!-- ./ historial-->
  </div>
</template>

<script>
	import Schema from '../core/Schema';
	import ClienteForm from '../reutilizables/clientes/ClienteForm';
  export default {
    components:{
			ClienteForm,
		},
    props: { },
    data () { return {
			buscarPor:"telefono",
			valorBuscar: null,
			editarCliente: false,
			clienteEdit: null,
			itemsBuscarPor: [
				{name:"Telefono", value:"telefono"},
				{name:"Nombre", value:"nors"},
				{name:"Calles(s)", value:"calles"},
			],
			clientes: [],
		};},
		mounted() {
			let self = this;
			this.$store.commit('setsplash',{texto:'Cargando', activar: true});
			Schema.models.Cliente.listar(30, 1, job => {
				this.$store.commit('setsplash',{texto:'', activar: false});
				this.clientes = job.newData;
			});
		},
    methods: {
			direccionCompleta(c) {
				return c.calles + ' ' 
				+ (c.numero_exterior?'#'+c.numero_exterior:'S/N') + ' ' 
				+ (c.numero_interior?'int. '+c.numero_interior:'') + ' ' 
				+ (c.asentamiento? c.asentamiento.tipo_asentamiento + ' ' + c.asentamiento.asentamiento: '');
			},
			nombre(c) {
				return c.razon_social? c.razon_social: c.encargado;
			},
			cambiarBuscarPor(value) {
				let indexActual = 0;
				for(let i= 0; i < this.itemsBuscarPor.length; i++) {
					if(this.buscarPor == this.itemsBuscarPor[i].value ) {
						indexActual = i + value;
					}
				}
				if(indexActual == -1) {
					indexActual = this.itemsBuscarPor.length -1;
				}
				if(indexActual == this.itemsBuscarPor.length) {
					indexActual = 0;
				}
				this.buscarPor = this.itemsBuscarPor[indexActual].value;
			},
			productos() {},
			buscar() {
				let self = this;
				if(!this.valorBuscar || this.valorBuscar.length <= 0) {
					return;
				}
				this.$store.commit('setsplash',{texto:'Cargando', activar: true});
				Schema.models.Cliente.buscarSimilar(this.buscarPor, this.valorBuscar, job => {
					this.$store.commit('setsplash',{texto:'', activar: false});
					this.clientes = job.newData;
				});
			},
			selectCliente(c) {
				this.$emit('getcliente', c);
			},
			cerrar() {
				this.$emit('getcliente', null);
			},
			editarCli(c) {
				this.clienteEdit = c;
				this.editarCliente = true;
			},
			obtenerClienteDeFormulario(c) {
				console.log(c);
				this.editarCliente = false;
			},
			colorSucursal(c) {
				let colores= {
					1:"matriz",
					2:"esme",
					3:"benito",
					4:"fun",
					8:"ham"
				};
				return (c.asentamiento?colores[c.asentamiento.sucursal_id]:'');
			},
			telefonos(c) {
				let tel = '';
				if(!!c.telefono) {
					tel += ' Tel.' + c.telefono;
				}
				if(!!c.movil) {
					tel += ' Movil.' + c.movil;
				}
				return tel;
			}
		},
		computed: {
			textoBuscarPor() {
				let text = "Ingresa ";
				switch(this.buscarPor) {
					case 'telefono': text += "el numero telefonico a buscar"; break;
					case 'calles': text += "la calle a buscar"; break;
					case 'razon_social': text += "el nombre a buscar"; break;
				};
				return text;
			},
		},
		watch: {}
  }
</script>
<style>
	.esme {
		background:#B4FFBB;
	}
	.benito {
		background:#FFB4EB!important;
	}
	.fun {
		background:#FFC97F;
	}
	.ham {
		background:#FFFDB1;
	}
	.matriz {
		background:#B1DFFF;
	}
	.botonSuc {
		width:210px;
		margin: 0px 2px 1px 10px;
		padding: 3px;
		border-radius: 10px;
		text-align:left;
		/*color: #FFFFFF;*/
	}
	.fila {
		border-bottom-style: solid;
		border-width: 1px;
	}
</style>
