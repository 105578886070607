<template>
	<div>
	<v-card height="100%" tile>
		<v-toolbar class="sub-cabecera" dark  dense> 
			<!--v-btn icon v-on:click="cerrar" > <i class="fa fa-times"></i></v-btn-->
			<v-spacer></v-spacer>
			<v-card-title>Users</v-card-title>
		</v-toolbar>
		<v-card-text>
			<v-row style="margin:0 0 0 0; padding:5px">
				<v-col class="no-mp" cols="2">
					<v-btn color="success" v-on:click="nuevo">Crear User</v-btn>
				</v-col>
				<v-col class="no-mp" cols="5"></v-col>
				<v-col class="no-mp" cols="5">
					<v-card style="border-radius:25px 25px 25px 25px; background-color:#6BB1F5"  elevation="1"  dark>
						<v-row class="no-mp">
							<v-col style="margin:0; padding:2vh 0 0 0; font-size:4vh; text-align:center; color:#FFFFFF;" cols="2">
								<i class="fa fa-search"></i>
							</v-col>
							<v-col class="no-mp" cols="10">
								<v-text-field filled rounded dense label="Buscar" :hide-details="true" v-model="valorBuscar"></v-text-field>
							</v-col>
						</v-row>
					</v-card>
				</v-col>
			</v-row>
			<v-card style="background-color:transparent!important" elevation="0">
				<v-card elevation="0" style="padding:5px 17px 5px 10px;margin:0; background-color:#F8F8F8;" outlined>
					<v-row class="no-mp">
						<v-col cols="5" class="no-mp">Nombre</v-col>
						<v-col cols="4" class="no-mp"></v-col>
						<v-col cols="1" class="no-mp"></v-col>
						<v-col cols="2" class="no-mp">Acciones</v-col>
					</v-row>
				</v-card>
				<v-card elevation="0" style="padding:1px 5px 1px 5px;margin:5px 0 0 0;background-color:rgba(16,45,80,0.1); 
				height:60vh; overflow-x: hidden; overflow-y: scroll;" outlined>
					<v-card elevation="0" style="padding:5px;margin:6px 0 0 0;" 
					v-for="(d, index) in users" :key="index">
						<v-row style="padding:0px;margin:0;" >
							<v-col cols="5" class="no-mp" style="font-size:14px;">{{d.nombre}}</v-col>
							<v-col cols="4" class="no-mp" style="font-size:13px;"></v-col>
							<v-col cols="1" class="no-mp"></v-col>
							<v-col cols="2" class="no-mp">
								<v-btn-toggle>
									<v-btn x-small :key="'cont'" depressed dark color="primary" v-on:click="contra(d,index)"><i class="fa fa-lock"></i></v-btn>
									<v-btn x-small :key="'edi'" depressed dark color="warning" v-on:click="editar(d,index)"><i class="fa fa-edit"></i></v-btn>
									<v-btn x-small :key="'tra'" depressed dark color="red" v-on:click="borrar(d,index)"><i class="fa fa-trash"></i></v-btn>
								</v-btn-toggle>
							</v-col>
						</v-row>
					</v-card>
				</v-card>
			</v-card>
		</v-card-text>
	</v-card>
		<!-- -->
		<v-dialog v-model="formModal" width="400" >
			<UserForm v-if="editarItem" :user="editarItem" v-on:getuser="getUser" :key="'edi'"/>
			<UserForm v-else-if="formModal" v-on:getuser="getUser" :key="'new'"/>
		</v-dialog>
		<!-- -->
		<!-- -->
		<v-dialog v-model="contraModal" width="400" >
			<UserPassword  v-if="editarItem" :user="editarItem" v-on:getuser="getUser"/>
		</v-dialog>
		<!-- -->
  </div>
</template>

<script>
	import Schema from '../core/Schema';
	import UserForm from '../reutilizables/users/UserForm'; 
	import UserPassword from '../reutilizables/users/UserPassword'; 
  export default {
    components:{
			UserForm,
			UserPassword,
		},
    props: { },
    data () { return {
			valorBuscar: null,
			editarItem: null,
			index: null,
			formModal: false,
			contraModal: false,
			users: [],
		};},
		mounted() {
			let self = this;
			this.$store.commit('setsplash',{texto:'Cargando', activar: true});
			Schema.models.User.listar(50, 1, (job) => {
				this.$store.commit('setsplash',{texto:'', activar: false});
				this.users = job.newData;
			});
		},
    methods: {
			buscar() {
				let self = this;
				this.$store.commit('setsplash',{texto:'Cargando', activar: true});
				if(!this.valorBuscar || this.valorBuscar.length <= 0) {
					this.$store.commit('setsplash',{texto:'', activar: false});
					Schema.models.User.listar(50, 1, (job) => {
						this.users = job.newData;
						return;
					});
				}
				Schema.models.User.buscarSimilar('nombre', this.valorBuscar, (result) => {
					this.$store.commit('setsplash',{texto:'', activar: false});
					this.users = result.newData;
				});
			},
			nuevo() {
				this.index = this.users.length;
				this.editarItem = null;
				this.formModal = true;
			},
			contra(item,index) {
				this.editarItem = item;
				this.index = index;
				this.contraModal = true;
			},
			editar(item,index) {
				this.editarItem = item;
				this.index = index;
				this.formModal = true;
			},
			borrar(item, index) {
			},
			getUser(p) {
				if(p) {
					this.users.splice(this.index,1,p);
				}
				this.formModal = false;
				this.contraModal = false;
			},
		},
		computed: {},
		watch: {
			valorBuscar() {
				this.buscar();
			}
		}
  }
</script>
<style>
</style>
