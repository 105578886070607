/*
import { DataTypes, Op } from 'sequelize';
export { DataTypes, Op };
//*/
export let DataTypes = {
	STRING:() => {},
	ENUM:() => {},
	FLOAT:() => {},
}; 
export let Op; 

