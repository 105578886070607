<template>
	<v-card >
		<v-toolbar color="#D3D5D8"  dense> 
			<v-btn icon v-on:click="cerrar" > <i class="fa fa-times"></i></v-btn>
			<v-spacer></v-spacer>
			<v-card-title>{{sucursal.nombre | capitalize}}</v-card-title>
		</v-toolbar>
		<v-row class="no-mp">
			<v-col class="no-mp" >
				<v-card elevation="0" outlined style="margin:10px; padding:20px; background-color:#F0F0F0;">
					<v-form ref="form">
						<v-text-field solo label="Nombre" v-model="sucursal.nombre"></v-text-field>
						<v-text-field solo label="Direccion" v-model="sucursal.direccion"></v-text-field>
						<v-text-field solo label="Telefono" v-model="sucursal.telefono" ></v-text-field>
						<v-text-field solo label="Encargado" v-model="sucursal.encargado" ></v-text-field>
						<v-text-field solo label="RFC" v-model="sucursal.rfc" ></v-text-field>
						<v-switch solo label="Activa" v-model="sucursal.activa" ></v-switch>
						<v-select label="Tipo" v-model="sucursal.tipo" :items="['caja','operatel']"></v-select>
					</v-form>					
					<v-btn block color="success" v-on:click="guardar">Guardar</v-btn>
				</v-card>
			</v-col>
		</v-row>
	</v-card>
</template>

<script>
	import Schema from '../../core/Schema';
  export default {
    components:{},
    props: { 
			sucursal: {
				type:Object,
				default() { return {
					nombre:null,
					direccion:null,
					telefono:null,
					encargado:null,
					rfc:null,
					activa:null,
					lat:null,
					lng:null,
					tipo:null,
				};}
			}
		},
    data () { return { };},
		mounted() {},
    methods: {
			guardar() {
				if(!this.$refs.form.validate()) {
					//alert("Faltan agregar campos");
					return;
				}
				this.$store.commit('setsplash',{texto:'Guardando', activar: true});
				Schema.models.Sucursal.guardar(this.sucursal,'all',(job) => {
					this.$emit('getsucursal',job.newData);
					this.$store.commit('setsplash',{texto:'', activar: false});
				});
			},
			cerrar() {
				this.$emit('getsucursal',null);
			}
		},
		computed: {},
		watch: {}
  }
</script>
<style>
</style>
