import { DataTypes, Op } from '../utils/DataTypes';
import AppModel from '../core/AppModel';
import Helper from '../core/Helper';

export default class Dia extends AppModel {
  constructor(sequelize, origin,crud) {
    super(sequelize, origin,crud);
    this.atributos = {
			id: {
				allowNull: false,
				primaryKey: true,
				type: DataTypes['STRING'],
				//defaultValue: NULL
			},
			inicio: {
				allowNull: false,
				type: DataTypes['FLOAT'],
				defaultValue: 0,
			},
			sucursal_id: {
				allowNull: false,
				type: DataTypes['INTEGER'],
				defaultValue: 0,
			},
			created_at: {
				allowNull: true,
				type: DataTypes['DATE'],
				defaultValue: DataTypes['NOW']
			},
		};
		this.fieldsValids = Object.keys(this.atributos);
		this.options = {
      tableName: 'dias',
			modelName: 'Dia',
			timestamps: false,
			freezeTableName: true,
			underscored: true,
      // Hooks: https://sequelize.org/master/manual/hooks.html
    };
    if(this.crud) {
			this.model = sequelize.define(this.options.modelName, this.atributos, this.options);
		}
  }// constructor.
  
  includeAssociations() {
		return [];
	}
	
	iniciarDia(sucursal_id,monto, handler) {
		console.log("suc",sucursal_id);
		console.log("monto",monto);
		this.attachExecuteJob({sucursal_id,monto},'_initDay','all',handler);
	}
	
	async _initDay(data) {
		console.log("_initDay",data);
		let newDay = await this._save({
			inicio: parseFloat(data.monto),
			sucursal_id:data.sucursal_id,
			created_at:Helper.fecha(),
			id:Helper.fecha() + ':' + data.sucursal_id,
		});
		return data;
	}
	
	diaIniciado(sucursal_id,handler) {
		this.attachSelectJob({sucursal_id},'_diaIniciado',handler);
	}
  
  async _diaIniciado(data) {
		let result = await this.model.findByPk(Helper.fecha() +':'+data.sucursal_id);
		return {dia_iniciado: result != null, dia:result};
	}
}
