<template>
		<v-container >
			<h2>Lista de Asentamientos</h2>
			<v-row>
				<v-col cols="1"><b>Buscar: </b></v-col>
				<v-col cols="8"><v-text-field v-model="valorBuscar" label="Nombre Asentamiento" v-on:keyup.enter="buscarAsentamientos">
				</v-text-field></v-col>
				<v-col cols="2"><v-btn v-on:click="buscarAsentamientos">Buscar</v-btn></v-col>
			</v-row>
			<v-simple-table>
				<template v-slot:default>
					<thead>
						<tr>
							<th class="text-left">ID</th>
							<th class="text-left">C.P.</th>
							<th class="text-left">Asentamiento</th>
							<th class="text-left">Tipo</th>
							<th class="text-left">Municipio</th>
							<th class="text-left">Sucursal</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(a, index) in asentamientos" :key="index">
							<td>{{ a.id }}</td>
							<td>{{ a.cp }}</td>
							<td>{{ a.asentamiento }}</td>
							<td>{{ a.tipo_asentamiento }}</td>
							<td>{{ a.municipio }}</td>
							<td>
								<v-select :items="sucursales" :item-text="'nombre'"  v-model="a.sucursal_id"
								:item-value="'id'" label="Pertence a"></v-select>
							</td>
							<td><v-btn v-on:click="guardarAsenta(a)"><i class="fa fa-check"></i></v-btn></td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
			<v-pagination v-model="page" :length="paginas" :total-visible="7" ></v-pagination>
		</v-container>
</template>

<script>
	import Helper from '../core/Helper';
	import Schema from '../core/Schema';
  export default {
    components:{},
    props: {},
    data: () => ({
			sucursales: [],
			asentamientos: [],
			page:0,
			paginas:0,
			limit:50,
			valorBuscar: null
		}),
		mounted() {
			this.$store.commit('setsplash',{texto:'Cargando', activar: true});
			Schema.models.Sucursal.listar(50,1,job => {
				this.sucursales = job.newData;
			});
			Schema.models.Asentamiento.count(job => {
				this.$store.commit('setsplash',{texto:'', activar: false});
				this.paginas = Math.ceil((job.newData / this.limit));
				//this.asentamientos = job.newData;
			});
			this.page = 1;
			this.buscarAsentamientos();
		},
    methods: {
			guardarAsenta(a) {
				a.updated_at = Helper.fechaHora();
				Schema.models.Asentamiento.guardar(a, 'all', job => {});
			},
			buscarAsentamientos() {
				this.$store.commit('setsplash',{texto:'Cargando', activar: true});
				if(!this.valorBuscar || this.valorBuscar.length <= 0) {
					Schema.models.Asentamiento.listar(this.limit,this.page,job => {
						this.$store.commit('setsplash',{texto:'', activar: false});
						this.asentamientos = job.newData;
					});
					return;
				}
				Schema.models.Asentamiento.buscarSimilar(this.valorBuscar,job => {
					this.$store.commit('setsplash',{texto:'', activar: false});
					this.asentamientos = job.newData;
				});
			}
		},
    computed: {
			itemsSucursales: function() {
				let suc = [];
				for(let i = 0; i < this.sucursales.length; i++) {
					suc.push({
						value: this.sucursales[i].nombre,
						name: this.sucursales[i].id
					});
				}
				return suc;
			}
		},
		watch: {}
	}
</script>
<style>
</style>
