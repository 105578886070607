import { DataTypes, Op } from '../utils/DataTypes';
import AppModel from '../core/AppModel';
import Helper from '../core/Helper';
import Schema from '../core/Schema';

export default class Pedido extends AppModel {
  constructor(sequelize, origin,crud) {
    super(sequelize, origin,crud);
    this.atributos = { 
			id: {
				allowNull: false,
				primaryKey: true,
				type: DataTypes['STRING'],
			},
			sucursal_id: {
				allowNull: false,
				type: DataTypes['INTEGER'],
				references: {
					model: 'Sucursal',
					key: 'id'
				}
			},
			empleado_id: {
				allowNull: false,
				type: DataTypes['INTEGER'],
				references: {
					model: 'User',
					key: 'id'
				}
			},
			repartidor_id: {
				allowNull: true,
				type: DataTypes['INTEGER'],
				references: {
					model: 'User',
					key: 'id'
				}
			},
			cliente_id: {
				allowNull: false,
				type: DataTypes['BIGINT'],
				references: {
					model: 'Cliente',
					key: 'id'
				}
			},
			tablajero_id: {
				allowNull: true,
				type:DataTypes['BIGINT'],
				references: {
					model: 'User',
					key: 'id'
				}
			},
			estado: {
				allowNull: false,
				type: DataTypes['ENUM']('apertura','produccion', 'armado','reparto','cobro','finalizado')
			},
			created_at: {
				allowNull: true,
				type: DataTypes['DATE'],
				defaultValue: DataTypes['NOW']
			},
			fecha_in_produccion: {
				allowNull: true,
				type: DataTypes['DATE']
			},
			fecha_out_produccion: {
				allowNull: true,
				type: DataTypes['DATE']
			},
			fecha_reparto: {
				allowNull: true,
				type: DataTypes['DATE']
			},
			fecha_entregado: {
				allowNull: true,
				type: DataTypes['DATE']
			},
			fecha_finalizado: {
				allowNull: true,
				type: DataTypes['DATE']
			},
			total: {
				allowNull: false,
				type: DataTypes['FLOAT']
			},
			pagos: {
				allowNull: false,
				type: DataTypes['FLOAT']
			},
			cancelado: {
				allowNull: true,
				type: DataTypes['BOOLEAN'],
				defaultValue: false,
			}
		};
		this.fieldsValids = Object.keys(this.atributos);
		this.options = {
      tableName: 'pedidos',
			modelName: 'Pedido',
			timestamps: false,
			freezeTableName: true,
			underscored: true,
      // Hooks: https://sequelize.org/master/manual/hooks.html
    };
    if(this.crud) {
			this.model = sequelize.define(this.options.modelName, this.atributos, this.options);
		}
  }// constructor.
  
  includeAssociations() {
		return [
			{model: this.sequelize.models.Cancelacion, as: 'cancelacion'},
			{model: this.sequelize.models.User, as: 'repartidor'},
			{model: this.sequelize.models.User, as: 'cajero'},
			{model: this.sequelize.models.User, as: 'tablajero'},
			{model: this.sequelize.models.ItemPedido, as: 'items', include:[{model: this.sequelize.models.Producto, as: 'producto'}]},
			{model: this.sequelize.models.PagoPedido, as: 'abonos', include:[{model: this.sequelize.models.User, as: 'cobrador'}]},
			{model: this.sequelize.models.Cliente, as: 'cliente', include:[{model: this.sequelize.models.Asentamiento, as: 'asentamiento'}]},
		];
	}
  
  //
  crearPedido(pedido,items, handler) {
		this.attachExecuteJob({pedido,items},'_savePedido','own',handler);
	}
  //
  
  async _savePedido(data) {
		let p = await this._save(data.pedido);
		let items = [];
		for(let i = 0; i < data.items.length; i++) {
			let item = data.items[i];
			item.pedido_id = p.id;
			items.push(item);
		}
		items = await Schema.models.ItemPedido._saveAll(items,p.sucursal_id);
		console.log("items guardados", items);
		return {pedido:p, items};
	}
	
	//
	abonar(pedido,abono,handler) { 
		this.attachExecuteJob({pedido,abono},'_abonar','own',handler);
	}
  //
  
  //
	async _abonar(data) { 
		let pedido = await this._save(data.pedido);
		data.abono.pedido_id = pedido[this.primaryKey];
		let abono =  await Schema.models.PagoPedido._save(data.abono,pedido.sucursal_id);
		return {pedido, abono};
	}
  //
  
  /**
   * @override
  **/
  async _save(data) {
		console.log("entrando al save");
		let model = null;
		if(!!data[this.primaryKey]) {
			model = await this.model.findByPk(data[this.primaryKey],{attributes: this.fieldsValids});
		} else {
			if(this.origin == 'server') {
				return null;
			}
			let idSig = await Schema.models.Id._getIdSiguiente('pedidos',data.sucursal_id);
			console.log("id,siguiente pedidos", idSig);
			data.id = idSig  + ':' + data.sucursal_id;
			await Schema.models.Id._amuentarId('pedidos', data.sucursal_id);
		}
		if(model) {
			this.fieldsValids.forEach((field) => {
				model[field] = !!data[field]?data[field]:model[field];
			});
			model.save();
		} else {
			data['created_at']= Helper.fechaHora();
			model = await this.model.create(data);
		}
		return model;
	}
  
  pedidosDeHoy(sucursal_id,handler) {
		this.attachSelectJob(sucursal_id,'_pedidosDeHoy',handler);
	}
  
  async _pedidosDeHoy(sucursal_id) { 
		return await this.model.findAll({
			where:{ 
				[Op.or]: [
					{[Op.or]:[{estado:'reparto'}, {estado:'cobro'}]},
					{estado:'finalizado',fecha_finalizado:{[Op.gte]:Helper.fecha()}, repartidor_id:{[Op.not]: null }}
				],
				cancelado: 0, // <-- checar si una sucursal cobra o visualiza pedido de otra sucursal
				sucursal_id: sucursal_id, // <-- checar si una sucursal cobra o visualiza pedido de otra sucursal
			},
			attributes: this.fieldsValids,
			include: this.includeAssociations(),
			order: [['id', 'ASC']]
		});
	}
  //
  
  /**
   * @override
  **/
  async _findBy(data) { 
		let where = {};
		if(data.campo === 'fecha') {
			where = {created_at:{[Op.between]: [data.valor + ' 00:00:00', data.valor + ' 23:59:59']}};
		} else {
			where = {[data.campo]:data.valor};
		}
		
		return await this.model.findAll({
			where,
			attributes: this.fieldsValids,
			include: this.includeAssociations(),
			order: [['id', 'ASC']]
		});
	}
	
	
	/**
   * @override
  **/
	async _saveAll(data) {
		return {
			pedidos: super._saveAll(data.pedidos),
			items: Schema.models.ItemPedido._saveAll(data.items),
			abonos: Schema.models.PagoPedido._saveAll(data.abonos),
		}
	}
	
	/**
   * @override
  **/
  async enviarDatosAlCliente(socket,fecha, sucursalId) {
		let pedidos = await this._dataSince({fecha}); // < cambiar la condicion de busqueda
		console.log("enviar al cliente " + this.options.modelName, pedidos.length);
		let data = {
			pedidos,
			items: [],
			abonos: [],
		};
		
		pedidos.forEach(p => {
			p.items.forEach( (i) => {
				data.items.push(i);
			});
			p.abonos.forEach( (a) => {
				data.abonos.push(a);
			});
		});
		socket.emit('crud_channel_client',{
			data,
			newData: data,
			action:'_saveAll',
			socket_id:socket.id,
			broadcast:'own',
			model:'Pedido',
		});
	}
	
	/**
   * @override
  **/
  async enviarDatosAlServer(fecha) {
		let pedidos = await this._dataSince({fecha});
		console.log("enviar al server " + this.options.modelName, pedidos.length);
		let data = {
			pedidos,
			items: [],
			abonos: [],
		};
		pedidos.forEach(p => {
			p.items.forEach( (i) => {
				data.items.push(i);
			});
			p.abonos.forEach( (a) => {
				data.abonos.push(a);
			});
		});
		this.socketCli.emit('crud_channel_server',{
			data,
			newData: data,
			action:'_saveAll',
			socket_id:this.socketCli.id,
			broadcast:'own',
			model:'Pedido',
		});
	}
  //
	
	/**
   * @override
  **/
  async _dataSince(data) {
		return await this.model.findAll({
			where: { [Op.or]: {
				'created_at': {[Op.gte]:data.fecha}, 
				'fecha_in_produccion': {[Op.gte]:data.fecha},
				'fecha_out_produccion': {[Op.gte]:data.fecha},
				'fecha_reparto': {[Op.gte]:data.fecha},
				'fecha_entregado': {[Op.gte]:data.fecha},
				'fecha_finalizado': {[Op.gte]:data.fecha},
			}},
			attributes: this.fieldsValids,
			include:[
				{model: this.sequelize.models.ItemPedido, as: 'items',include:[{model: this.sequelize.models.Producto, as: 'producto'}]},
				{model: this.sequelize.models.PagoPedido, as: 'abonos'},
			],
			order: [[this.primaryKey, 'ASC']],
		});
	}
  //
  
  pedidosDiaSucursal(sucursal_id,fecha,handler) { 
		this.attachExecuteJob({sucursal_id,fecha},'_pedidosDiaSucursal','own',handler);
	}
  
  async _pedidosDiaSucursal(data) {
		return await this.model.findAll({
			where: {
				created_at:{[Op.between]: [data.fecha + ' 00:00:00', data.fecha + ' 23:59:59']},
				sucursal_id:data.sucursal_id,
			},
			attributes: this.fieldsValids,
			include: this.includeAssociations(),
			order: [[this.primaryKey, 'ASC']],
		});
	}
	
	aperturados(handler) { 
		this.attachExecuteJob({},'_aperturados','own',handler);
	}
  
  async _aperturados(data) {
		return await this.model.findAll({
			where: { cancelado: 0, estado:'apertura'},
			attributes: this.fieldsValids,
			include: this.includeAssociations(),
			order: [[this.primaryKey, 'ASC']],
		});
	}
	
	producidos(handler) { 
		this.attachExecuteJob({},'_producidos','own',handler);
	}
  
  async _producidos(data) {
		return await this.model.findAll({
			where: { cancelado: 0, estado:'produccion'},
			attributes: this.fieldsValids,
			include: this.includeAssociations(),
			order: [[this.primaryKey, 'ASC']],
		});
	}
  
}
