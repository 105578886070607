const Path = require('path');
var fs=require('fs');
import MD5 from "crypto-js/md5";

export default { 
	textoConSaltos(texto, limite) {
		let lineas = [];
		let numLineas = 0;
		let count = 0;
		for(let i = 0; i < texto.length; i++) {
			if(count == limite) {
				numLineas++;
				count = 0;
			}
			if(!lineas[numLineas]) {
				lineas[numLineas] = '';
			}
			lineas[numLineas] += texto[i];
			count++;
		}
		return lineas;
	},
	
	leerJsonExterno(source) {
		const fullPath =  Path.resolve(__dirname, source);
		console.log('leyendo Archivo',fullPath);
		var data = fs.readFileSync(fullPath, 'utf8');
		return JSON.parse(data);
	},
	
	fecha(d) {
		if(!d) {
			d = new Date();
		}
		let mes = (d.getMonth() + 1);
		let dia = d.getDate();
		return d.getFullYear() + '-' + (mes<10?'0':'') + mes + '-' + (dia<10?'0':'') + dia;
	},
	hora(d) {
		if(!d) {
			d = new Date();
		}
		let min = d.getMinutes();
		let seg = d.getSeconds();
		return d.getHours() + ':' + (min<10?'0':'') + min + ':' + (seg<10?'0':'') + seg;
	},
	fechaHora() {
		return this.fecha() + ' ' + this.hora();
	},
	fechaToString(d) {
		return this.fecha(d) + ' ' + this.hora(d);
	},
	stringToDate(stringDate) { 
		if (!stringDate) {
      return new Date();
    }
		let anho = 0, mes = 0, dia = 0, h = 0, min = 0, seg = 0;
		let index = stringDate.search(/\d\d:\d\d:\d\d/);
		let hour = index >= 0? stringDate.substring(index,(index + 8)):null;
		index = stringDate.search(/\d\d\d\d-\d\d-\d\d/);
		let date = index >= 0? stringDate.substring(index,(index+10)):null;
		if(date) {
			anho = Number(date.split("-")[0]);
			mes = Number(date.split("-")[1]) - 1;
			dia = Number(date.split("-")[2]);
		}
		if(hour) {
			h = Number(hour.split(":")[0]);
			min = Number(hour.split(":")[1]);
			seg = Number(hour.split(":")[2]);
		}
		//return new Date().toLocaleString("es-MX", {timeZone: 'America/Mexico_City'})
		return new Date(anho,mes,dia,h,min,seg); // date no hacepta el formato en string YYY-MM-DD en el constructor
	},
	fechaToMinutos(d) {
		let diasPorMes = {
			0:0, 1:31, 2:28, 3:31, 4:30, 5:31, 6:30, 7:31,  8:31, 9:30,
			10:31, 11:30, 12:31,
		};
		return (d.getFullYear() * 365 * 24 * 60) 
		+ (diasPorMes[d.getMonth()+1] * 24 * 60) 
		+ (d.getDate() * 24 * 60) + (d.getHours() * 60) + d.getMinutes();
	},
	tiempoTranscurrido(fechaInicio) { // devuelve los minutos transcurridos de sde una fecha hasta el momento actual
		let fAux = fechaInicio;
		if((typeof fechaInicio).toUpperCase() === 'STRING') {
			fAux = this.stringToDate(fAux);
		}
		return this.fechaToMinutos(new Date()) - this.fechaToMinutos(fAux);
	},
	restarFechas() { //regresa el restante en minutos
		return 10;
	},
	hash(text) {
		return MD5(text).toString()
	},
};
