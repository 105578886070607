export default [
"Guadalupe Etla",

"Magdalena Apasco",

"Nazareno Etla",

"Reyes Etla",

"San Agustín Etla",

"San Andrés Zautla",

"San Felipe Tejalapam",

"San Francisco Telixtlahuaca",

"San Jerónimo Sosola",

"San Juan Bautista Atatlauca",

"San Juan Bautista Guelache",

"San Juan Bautista Jayacatlan",

"San Juan del Estado",

"San Lorenzo Cacaotepec",

"San Pablo Etla",

"San Pablo Huitzo",

"Villa de Etla",

"Santa María Peñoles",

"Santiago Suchilquitongo",

"Santiago Tenango",

"Santiago Tlasoyaltepec",

"Santo Tomás Mazaltepec",

"Soledad Etla",

"San Antonio Huitepec",

"San Miguel Peras",

"San Pablo Cuatro Venados",

"Santa Ines del Monte",

"Trinidad Zaachila",

"Villa de Zaachila",

"Ciénega de Zimatlan",

"Magdalena Mixtepec",

"San Antonio El Alto",

"San Bernardo Mixtepec",

"San Miguel Mixtepec",

"San Pablo Huixtepec",

"Santa Ana Tlapacoyan",

"Santa Catarina Quiane",

"Santa Cruz Mixtepec",

"Santa Gertrudis",

"Santa Ines Yatzeche",

"Ayoquezco de Aldama",

"Zimatlán de Alvarez",

"Cuilapam de Guerrero",

"Oaxaca de Juárez",

"San Agustin de Las Juntas",

"San Agustin Yatareni",

"San Andrés Huayapam",

"San Andrés Ixtlahuaca",

"San Antonio de la Cal",

"San Bartolo Coyotepec",

"San Jacinto Amilpas",

"Animas Trujano",

"San Pedro Ixtlahuaca",

"San Raymundo Jalpan",

"San Sebastián Tutla",

"Santa Cruz Amilpas",

"Santa Cruz Xoxocotlan",

"Santa Lucia del Camino",

"Santa María Atzompa",

"Santa María Coyotepec",

"Santa María El Tule",

"Santo Domingo Tomaltepec",

"Tlalixtac de Cabrera",

"Magdalena Teitipac",

"Rojas de Cuahutemoc",

"San Bartolomé Quialana",

"San Dionisio Ocotepec",

"San Francisco Lachigolo",

"San Juan del Rio",

"San Juan Guelavia",

"San Juan Teitipac",

"San Lorenzo Albarradas",

"San Lucas Quiavini",

"San Pablo Villa De Mitla",

"San Pedro Quiatoni",

"San Pedro Totolapan",

"San Sebastián Abasolo",

"San Sebastián Teitipac",

"Santa Ana del Valle",

"Santa Cruz Papalutla",

"Santa María Guelace",

"Santa María Zoquitlan",

"Santiago Matatlan",

"Santo Domingo Albarradas",

"Teotitlan del Valle",

"San Jeronimo Tlacochahuaya",

"Tlacolula de Matamoros",

"Villa de Diaz Ordaz",

"Coatecas Altas",

"La Compañía",

"Heroica Cd. de Ejutla de Crespo",

"La Pe",

"San Agustin Amatengo",

"San Andrés Zabache",

"San Juan Lachigalla",

"San Martin de Los Cansecos",

"San Martin Lachila",

"San Miguel Ejutla",

"San Vicente Coatlan",

"Taniche",

"Yogana",

"Asunción Ocotlán",

"Magdalena Ocotlan",

"Ocotlan de Morelos",

"San José del Progreso",

"San Antonino Castillo Velasco",

"San Baltazar Chichicapam",

"San Dionisio Ocotlan",

"San Jerónimo Taviche",

"San Juan Chilateca",

"San Martín Tilcajete",

"San Miguel Tilquiapam",

"San Pedro Apóstol",

"San Pedro Martir",

"San Pedro Taviche",

"Santa Ana Zegache",

"Santa Catarina Minas",

"Santa Lucia Ocotlan",

"Santiago Apóstol",

"Santo Tomás Jalieza",

"Yaxe"
]
