import has from "has";
import Helper from './Helper';
class Filter {
  constructor() {
    if (typeof Filter.instance === 'object') {
      return Filter.instance;
    }
    Filter.instance = this;
    return this;
  } // constructor

// Extrae la parte del folio de un pedido id
  folio(pedidoId) {
    if (/\d+:\d+/.test(pedidoId)) {
      return pedidoId.split(":")[0];
    }
    return pedidoId;
  }

// Devuelve el nombre de una catengoria a partir de su id
  catNombre(categoriaID) {
    let cats = ["", "Res", "Cerdo", "Pollo", "Pez", "Lacteos", "Otros"];
    if (categoriaID > 0 && categoriaID < cats.length) {
      return cats[categoriaID];
    }
    return `Categoria ${categoriaID}`;
  }

// Devuelve un numero en formato $ 00.00
  moneda(str, signo) {
    let num = new Number(str);
    if (isNaN(num)) {
      num = 0;
    }
    num = num.toLocaleString(
      "es-MX", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2
        }
    );
    return (signo?'$':'') + num;
  }

// Devuelve la fecha/hora en formato: "dd/mm/yy hh:mm AM/PM"
  dateTime(value) {
    if (!value) {
      return "--/--/-- --:--AM";
    }
    var f = new Date(value);
    const y = new Intl.DateTimeFormat("es-MX", { year: "2-digit" }).format(f);
    const m = new Intl.DateTimeFormat("es-MX", { month: "long" })
      .format(f).substring(0, 3);
    const d = new Intl.DateTimeFormat("es-MX", { day: "2-digit" }).format(f);
    let twoDig = dig => {
      return dig < 10 ? "0" + dig : dig;
    };
    let h = f.getHours();
    let med = h > 11 ? " PM" : " AM";
    h = twoDig(h % 12);
    let min = twoDig(f.getMinutes());
    h = h + ":" + min + med;
    return d + "/" + m + "/" + y + " " + h;
  }
  
// Devuelve la fecha/hora en formato: "dd/mm/yy"
  date(value) {
    if (!value) {
      return "--/--/--";
    }
    var f = Helper.stringToDate(value);
    const y = new Intl.DateTimeFormat("es-MX", { year: "2-digit" }).format(f);
    const m = new Intl.DateTimeFormat("es-MX", { month: "long" })
      .format(f).substring(0, 3);
    const d = new Intl.DateTimeFormat("es-MX", { day: "2-digit" }).format(f);
    
    return d + "/" + m + "/" + y;
  }
  
  capitalize(text) {
		if (typeof text !== 'string') {
			return '';
		}
		return text.charAt(0).toUpperCase() + text.slice(1)
	}

/**
 * Carga los Filtros en el objeto vue
 */
  load(Vue) {
    if (!has(Vue, "filter") || typeof Vue.filter != 'function') {
      return false;
    }
    console.log("agregando filtro");
    Vue.filter("folio", this.folio);
    Vue.filter("catNombre", this.catNombre);
    Vue.filter("moneda", this.moneda);
    Vue.filter("dateTime", this.dateTime);
    Vue.filter("date", this.date);
    Vue.filter("capitalize", this.capitalize);
    return true;
  }
}

export default new Filter();
